import React from "react"
import styled from "styled-components"
import { LanguageContext } from "../context/languageContextProvider"

function Hero() {
  const [language, setLanguage] = React.useContext(LanguageContext)

  return (
    <HeroMain>
      {language === "EN" && (
        <h1>
          The ONE Wedding Plaza
          <br />
          <br />
          Jenn's Wedding Studio
          <br />
          <br />
          New York Bridal Inc.
          <br />
          <br />
          Imperial Bridal Inc.
        </h1>
      )}
      {language === "CN" && (
        <h1>
          紐約歐陸經典婚紗<br></br>
          婚慶娛樂公司
          <br></br>
          <br></br>
          纽约新娘之家
          <br></br>
          <br></br>
          皇庭新娘婚纱
        </h1>
      )}
    </HeroMain>
  )
}

export default Hero

const HeroMain = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.5),
      rgba(147, 181, 179, 0.1)
    ),
    url("https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-41v_qj8qmn.jpg");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  h1 {
    color: white;
    font-size: 3rem;
    text-align: center;
    font-family: "Noto Serif Display", serif;
    font-weight: 700;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }
`
