import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MikeCoFounder from "../images/leadership/mike2-min.png"
import Jennifer from "../images/leadership/jennifer.jpg"
import Andy from "../images/mcs/andy-min.png"
import Cindy from "../images/mcs/cindy-min.png"
import Elaine from "../images/mcs/elaine-min.png"
import Feng from "../images/mcs/feng-min.png"
import Jiejie from "../images/mcs/jiejie-min.png"
import Sisi from "../images/mcs/sisi-min.jpg"
import Lisa from "../images/mcs/lisa-min.png"
import Leo from "../images/mcs/leo-min.png"
import Tsang from "../images/mcs/tsang-min.png"
import Zaozao from "../images/mcs/zaozao-min.png"
import Isabel from "../images/mcs/isabel-min.png"
import Ting from "../images/mcs/ting-min.png"
import Sam from "../images/mcs/sam-min.png"
import Vivan from "../images/mcs/vivian.jpg"
import Candice from "../images/mcs/candice.jpg"
import Christine from "../images/mcs/christine.jpg"
import Weng from "../images/mcs/weng.jpg"
import acca from "../images/clients/acca-min.png"
import accf from "../images/clients/accf-min.png"
import brace from "../images/clients/brace-min.png"
import cwa from "../images/clients/cwa-min.png"
import dongQi from "../images/clients/dong-qi-min.png"
import fcaa from "../images/clients/fcaa-min.png"
import fuijian from "../images/clients/fuijian-min.png"
import hca from "../images/clients/hca-min.png"
import kw from "../images/clients/kw-min.png"
import mba from "../images/clients/mba-min.png"
import npopa from "../images/clients/npopa-min.png"
import nyBeer from "../images/clients/ny-beer-min.png"
import nyChinese from "../images/clients/ny-chinese-min.png"
import oriental from "../images/clients/oriental-min.png"
import panda from "../images/clients/panda-min.png"
import tasteTown from "../images/clients/taste-town-min.png"
import theOne from "../images/clients/the-1-min.png"
import tjca from "../images/clients/tjca-min.png"
import r from "../images/clients/r-min.png"
import emo from "../images/clients/emo-min.png"
import geely from "../images/clients/geely-min.jpg"
import goldenImperial from "../images/clients/golden-imperial-min.jpg"
import goldenUnicorn from "../images/clients/golden-unicorn-min.jpg"
import nyStudio from "../images/clients/ny-studio-min.jpg"
import otv from "../images/clients/otv-min.jpg"
import orientalPalm from "../images/clients/oriental-palm-min.jpg"
import sparks from "../images/clients/sparks-min.jpg"
import zhenPin from "../images/clients/zhen-pin-min.jpg"
import LG from "../images/clients/lg-min.png"
import Lam from "../images/clients/lam-min.png"
import sunflower from "../images/clients/sunflower-min.jpg"
import yeung from "../images/clients/yeung-min.jpg"
import leo from "../images/clients/leo-min.png"
import century from "../images/clients/century-min.png"
import afaci from "../images/clients/afaci-min.png"
import uhtag from "../images/clients/uhtag.png"
import ulcaa from "../images/clients/ulcaa.png"
import Photographers from "../images/services/photographers.jpg"
import { LanguageContext } from "../context/languageContextProvider"

const coFounders = [
  {
    EN: "Michael Lin",
    CN: "Michael Lin",
    image: MikeCoFounder,
  },
  {
    EN: "Yu Men Weng",
    CN: "Yu Men Weng",
    image: Weng,
  },
]

const emcees = [
  {
    EN: "Amos Leo",
    CN: "Amos Leo",
    image: Leo,
    infoEN:
      "Professional emcee, host, and party motivator proficient in Mandarin, English, and Cantonese. Specialized in officiating wedding ceremonies, hosting receptions, chic wedding planner, large-scale events planner, and various celebration events.",
    infoCN:
      "專業主持人，精通普通話英語 和粵語，時尚婚禮策劃師和大型 活動策劃師.",
  },
  {
    EN: "Sisi",
    CN: "Sisi",
    image: Sisi,
    infoEN:
      "Registered officiant / Professional emcee / Party motivator. Specialized in officiating wedding ceremonies, hosting receptions, and various celebration events. Sisi is the expert for leading games to boost up the party atmosphere! Fluent in English, Mandarin, and Cantonese",
    infoCN:
      "婚禮儀式、酒席宴客、以及各種大小型的慶典活動，我們為您呈現—》專業的主持+精湛的場控+敬業的精神+私人定製的流程+氣氛帶動+搭配sisi貼心的服務態度=令您無可挑剔！精通英語，國語，广东话！",
  },
  {
    EN: "Zaozao Wang",
    CN: "王早早",
    image: Zaozao,
    infoEN:
      "Specialized in Chinese/English bilingual hosting for large-scale celebration events, political forums, and weddings.",
    infoCN: "专业从事大型庆典活动、政治论坛、婚礼等中英文双语主持.",
  },
  {
    EN: "Isabel",
    CN: "武子𤋮",
    image: Isabel,
    infoEN:
      "Specialized in Chinese/English bilingual hosting for large-scale celebration events and weddings. Also a body fitness trainer and insurance agent.",
    infoCN: "专业从事大型庆典活动婚礼等中英文双语主持以及形体教练保险专业.",
  },
  {
    EN: "Sam",
    CN: "邢光程",
    image: Sam,
    infoEN:
      "Proficient in Mandarin and English, Fuzhou natives can host weddings, banquets, and community events. Also a special florist.",
    infoCN:
      "福州人精通普通话和英语，可以举办婚礼、宴会和社区活动。还有专门的花店.",
  },
  {
    EN: "Ting",
    CN: "赵晓婷",
    image: Ting,
    infoEN:
      "Proficient in Mandarin and English, Fuzhou natives can host weddings, banquets, and community events. Also a special florist.",
    infoCN:
      "福州人精通普通话和英语，可以举办婚礼、宴会和社区活动。还有专门的花店.",
  },
  {
    EN: "Vivian Li",
    CN: "Vivian Li",
    image: Vivan,
    infoEN:
      "With over a decade of experience as a New York State Ordained Wedding Officiant and professional Emcee, expertise spans large-scale events, including weddings, corporate gatherings, Sweet 16s, and birthday celebrations. Fluent in English and Mandarin, every event is ensured to flow seamlessly, avoiding awkward silences or dull moments. Dedicated to creating memorable and engaging experiences for every occasion.",
    infoCN:
      "作为纽约州认可的婚礼司仪和专业司仪，他拥有十多年的丰富经验，专业领域涵盖婚礼、公司聚会、甜蜜十六岁生日和生日庆祝等大型活动。他能说流利的英语和普通话，确保每场活动都能顺利进行，避免出现尴尬的沉默或沉闷的时刻。致力于为各种场合创造令人难忘、引人入胜的体验。",
  },
  {
    EN: "Candice Liang",
    CN: "Candice Liang",
    image: Candice,
    infoEN:
      "Candice, is a Columbia University graduate and proud former Miss Hawaii, Miss Chinese USA - Miss Congeniality 2023. With a background as diverse as her talents, she brings energy, elegance, and expertise to every stage I step on. Specializing in hosting political forums, weddings, pageants, and large-scale events, she thrives on creating unforgettable experiences that leave a lasting impression. Her passion for connecting with audiences is matched by her unique ability to combine professionalism with a warm, approachable style. Beyond the microphone, she’s also a seasoned performer who sings, dances, and plays the piano, adding a creative flair to any occasion. Whether it’s celebrating love, empowering individuals, or engaging in meaningful discussions, she’s here to make your event extraordinary.",
    infoCN:
      "Candice毕业于哥伦比亚大学，是前夏威夷小姐、美国华裔小姐--2023年最佳小姐的骄傲。她的背景和她的才华一样多元化，她为我踏上的每一个舞台都带来了活力、优雅和专业知识。她擅长主持政治论坛、婚礼、选美和大型活动，致力于创造令人难忘的经历，给人留下深刻印象。她热衷于与观众沟通，并以其独特的能力将专业精神与热情、平易近人的风格完美结合。除了麦克风，她还是一位经验丰富的表演者，会唱歌、跳舞和弹钢琴，为任何场合增添创意风采。无论是庆祝爱情、增强个人能力，还是进行有意义的讨论，她都能让您的活动与众不同",
  },
  {
    EN: "Christine",
    CN: "Christine",
    image: Christine,
    infoEN:
      "Registered officiant / Professional emcee / Party motivator. Specialized in officiating wedding ceremonies, hosting wedding receptions, sweet Sixteen, corporate party, and various celebration events. Christine is the expert for leading games to boost up the party atmosphere, she’s also a great singer! Fluent in English, Mandarin, Taiwanese and Cantonese.",
    infoCN:
      "注册司仪/专业司仪/派对激励师。擅长主持婚礼仪式、举办婚宴、甜蜜十六岁生日派对、企业派对和各种庆祝活动。Christine 擅长带领大家玩游戏，烘托派对气氛，她还是一位出色的歌手！精通英语、普通话、台语和粤语。",
  },
  {
    EN: "Cindy",
    CN: "小Cindy",
    image: Cindy,
    infoEN: "",
    infoCN: "",
  },
  {
    EN: "Lisa",
    CN: "Lisa",
    image: Lisa,
    infoEN: "",
    infoCN: "",
  },
  {
    EN: "Feng",
    CN: "何峰",
    image: Feng,
    infoEN: "",
    infoCN: "",
  },
  {
    EN: "Miss Tsang",
    CN: "曾小姐",
    image: Tsang,
    infoEN: "",
    infoCN: "",
  },

  {
    EN: "Andy Cheng",
    CN: "Andy Cheng",
    image: Andy,
    infoEN: "",
    infoCN: "",
  },
  {
    EN: "Elaine",
    CN: "Elaine",
    image: Elaine,
    infoEN: "",
    infoCN: "",
  },
]

const AboutPage = () => {
  const [language, setLanguage] = React.useContext(LanguageContext)

  return (
    <Layout>
      <Seo title="About" />
      <AboutHeader />
      <AboutSection>
        <h1>{language === "EN" ? "Our Services" : "我们的服务"}</h1>
        <h2>
          {language === "EN"
            ? "One Stop Wedding Planner Service"
            : "一站式婚礼策划一条龙服务"}
        </h2>
        {language === "EN" ? (
          <p>
            What does one-stop wedding mean?
            <br></br>
            <br></br>
            The planning of the entire wedding process in our The ONE Wedding
            Studio is very detailed. For each perfect wedding, we have to handle
            many things that need to be prepared. Newcomers are often in a
            hurry. It is against this background that the entire wedding process
            is handled by us, which can save a lot of energy for newcomers.
            <br></br>
            <br></br>
            The so-called one-stop wedding, in simple words, we have all the
            services and items to be used in the wedding. One-stop wedding
            includes:
            <br></br>
            <br></br>* The booking of restaurants, the ordering dishes, the
            ordering of gifts such as wine, water and oil!
            <br></br>
            <br></br>* A team of MC hosts who stay up to date with the latest
            trends in Mandarian, English, Cantonese, and Fuzhouese!
            <br></br>
            <br></br>* Wedding dresses, gowns shooting interior and exterior
            scenes, large photo frames, exquisite photo albums including the
            main wedding couple of both parties, friends and relatives, brides
            and grooms, bridesmaids and groomsmen, all personnel wear clothes
            for free, free makeup is free of charge, you only need to pay the
            basic tip!
            <br></br>
            <br></br>* Photography, wedding planning, bridal makeup, wedding car
            package!
            <br></br>
            <br></br>* All-day service, on-site make-up, pick-up, shooting 🎬
            location, holding 🌹, follow the float, bride with makeup!
            <br></br>
            <br></br>* Gorgeous arrangement of stage venue, lighting, sound
            system equipment, dj , Singer, bride holding flowers, including two
            parties, the bride and groom, bridesmaids and groomsmen, a total of
            8 rose corsages, and guest corsages, for the services required for
            the wedding, can be found here, do not spend anymore time searching.
            For newcomers who are pressed for time, choosing a one-stop wedding
            is the best. It can save the newcomers a lot of time and unnecessary
            stress, and choosing our one-stop wedding can give you a perfect
            wedding. The one-stop wedding also has price advantages, there are
            installment payment methods; newcomers with a tight budget should
            consider choosing our comprehensive one-stop service platform!
          </p>
        ) : (
          <p>
            一站式婚礼是什么意思 一站式婚礼包含什么内容
            <br></br>
            <br></br>
            我们欧陆经典婚庆城的整个婚礼流程的策划是非常详细的，为了每一场完美的婚礼我们应要办理需要准备的事情非常多，新人常常手忙脚乱，不知道从何准备起，而一站式婚礼就是在这样的背景下兴起的，整个婚礼的流程由我们一手包办，可以给新人省下不少力气。
            <br></br>
            <br></br>
            站式婚礼是什么意思所谓一站式婚礼，用简单的话来说就是，婚礼要用到的服务和项目我们都有。一站式婚礼包括：
            <br></br>
            <br></br>*
            帮忙预定酒楼，帮助订菜选菜肴，帮忙订购葡萄酒水，油之类赠送品！
            <br></br>
            <br></br>*
            拥有时代潮流强劲的司仪主持人团队、中英文，广东话，福州话，
            <br></br>
            <br></br>*
            婚纱，礼服拍摄内外景，放大相框，精美相册，包括双方主婚人以及亲朋好友、新娘新郎，伴娘伴郎，所有人员衣服免费穿戴，免费化妆不收费，只需自付基本小费
            <br></br>
            <br></br>*
            婚礼当天半天服务：拍照摄影、婚礼策划、新娘跟妆、婚车包接！
            <br></br>
            <br></br>*
            全天服务，上门化妆，接亲，拍摄🎬外景，手捧🌹，花车跟随，新娘跟妆！
            <br></br>
            <br></br>* 舞台场地华丽的布置、灯光，音响系统设备，dj
            ,歌手，新娘手捧花，包含两方主婚人与新娘新郎，伴娘伴郎，一共玫瑰胸花8朵，兼嘉宾胸花，对于婚礼中所需的服务，在这里都可得到解决，不用多花时间去其他家搜罗和了解，一站式婚礼就能全部完成。
            对于时间紧迫的新人来说选择一站式婚礼是最好不过的了，能为新人节省不少时间和不必要的环节，交给我们一站式婚礼就能给你一场完美的婚礼。而且我们欧陆经典婚庆公司，一站式婚礼还有价格优势，也有代款分期付款方式，预算紧凑的新人可以考虑首选我们全方位一站式服务平台！
          </p>
        )}

        <ServicesGrid>
          <ServiceBox>
            <StaticImage
              src={"../images/services/music-min.png"}
              alt="Music"
              height={300}
              width={300}
            />
            <div>
              <p>Music</p>
            </div>
          </ServiceBox>
          <ServiceBox>
            <StaticImage
              src={"../images/services/photobooth-min.png"}
              alt="Photobooth"
              height={300}
              width={300}
            />
            <div>
              <p>Photobooth</p>
            </div>
          </ServiceBox>
          <ServiceBox>
            <StaticImage
              src={"../images/services/lighting-min.png"}
              alt="Lighting"
              height={300}
              width={300}
            />
            <div>
              <p>Lighting</p>
            </div>
          </ServiceBox>
        </ServicesGrid>
      </AboutSection>
      <AboutSection>
        <h2>{language === "EN" ? "Our Founders" : "我们的创始人"}</h2>
        <LeadershipGrid>
          {coFounders.map(mc => {
            if (!mc.infoEN) {
              return (
                <MCBox key={mc["EN"]} showShadow={true} leader={true}>
                  <img src={mc.image} alt={mc["EN"]} />
                  <div>
                    <p>{mc[language]}</p>
                  </div>
                </MCBox>
              )
            } else {
              return null
            }
          })}
        </LeadershipGrid>
        <h3>Wedding and entertainment planners, Asian American community leaders, project financing investors, restaurant owners, and real estate investors.</h3>
      </AboutSection>
      <AboutSection>
        <h2>{language === "EN" ? "Our Director of Operations" : "我们的运营总监"}</h2>
        <LeadershipGrid>
          <MCBox key={"Jennifer Lin"} showShadow={true} leader={true}>
                  <img src={Jennifer} alt={"Jennifer Lin"} />
                  <div>
                    <p>{"Jennifer Lin"}</p>
                  </div>
          </MCBox>
        </LeadershipGrid>
      </AboutSection>
      <AboutSection>
        <h2>{language === "EN" ? "Our COO & Event Coordinator" : "我们的首席运营官兼活动协调员"}</h2>
        <LeadershipGrid>
          <MCBox key={"Amos Leo"} showShadow={true} leader={true}>
                  <img src={Leo} alt={"Amos Leo"} />
                  <div>
                    <p>{"Amos Leo"}</p>
                  </div>
          </MCBox>
        </LeadershipGrid>
      </AboutSection>
      <AboutSection>
        <h2>
          {language === "EN"
            ? "Our Masters of Ceremony"
            : "超时代潮流强劲的金牌主持人"}
        </h2>
        <MCGridInfo>
          {emcees.map(mc => {
            if (mc.infoEN) {
              return (
                <MCBoxInfo key={mc["EN"]}>
                  <MCBox showShadow={false}>
                    <img src={mc.image} alt={mc["EN"]} />
                    <div>
                      <p>{mc[language]}</p>
                    </div>
                  </MCBox>
                  <MCInfo>
                    <span>{language === "EN" ? mc.infoEN : mc.infoCN}</span>
                  </MCInfo>
                </MCBoxInfo>
              )
            } else {
              return null
            }
          })}
        </MCGridInfo>
        <MCGrid>
          {emcees.map(mc => {
            if (!mc.infoEN) {
              return (
                <MCBox key={mc["EN"]} showShadow={true}>
                  <img src={mc.image} alt={mc["EN"]} />
                  <div>
                    <p>{mc[language]}</p>
                  </div>
                </MCBox>
              )
            } else {
              return null
            }
          })}
        </MCGrid>
      </AboutSection>
      <AboutSection>
        <h2>{language === "EN" ? "Our Photographers" : "我们的摄影师"}</h2>
        <p>
          {language === "EN"
            ? "These four heavenly kings are our interior and exterior photographers. They are very professional. They are not available outside. You can only have them in The ONE Wedding Studio."
            : "这四大天王是我们的内外景拍照师，非常专业的，外面请不到的，只能在欧陆经典才能拥有他们"}
        </p>
        <PhotographersPic src={Photographers} alt="Photographers" />
      </AboutSection>
      <AboutSection>
        <h2>
          {language === "EN"
            ? "Our Clients and Partners"
            : "我们的客户和合作伙伴"}
        </h2>
        <p className="partner-text">
          Peter Tu. General consultant of FCBA, 華商會首席顧問 杜彼得
        </p>
        <p className="partner-text">
          John Lam. Founder, Chairman & Chief Executive Officer of Lam Group
        </p>
        <p className="partner-text">Cheng, Yeung & Lee PLLC 鄭楊李聯合律師樓</p>
        <ClientGrid>
          <img src={acca} alt="ACCA Logo"></img>
          <img src={accf} alt="ACCF NY Logo"></img>
          <img src={brace} alt="BRACE Logo"></img>
          <img src={cwa} alt="CWA Logo"></img>
          <img src={dongQi} alt="American Dong Qi Association Logo"></img>
          <img src={fcaa} alt="FCAA Logo"></img>
          <img
            src={fuijian}
            alt="Fujian-Taiwan General Chamber of Commerce of USA Logo"
          ></img>
          <img src={hca} alt="HCA Logo"></img>
          <img src={kw} alt="KW Technology Logo"></img>
          <img src={mba} alt="MBA Logo"></img>
          <img src={npopa} alt="NPOPA Logo"></img>
          <img src={nyBeer} alt="Classic New York Beer Logo"></img>
          <img
            src={nyChinese}
            alt="American New York Chinese Association Logo"
          ></img>
          <img src={oriental} alt="Oriental Group Logo"></img>
          <img src={panda} alt="Panda US Holding Group Logo"></img>
          <img src={tasteTown} alt="Taste Town Logo"></img>
          <img src={theOne} alt="The 1 Logo"></img>
          <img src={tjca} alt="TJCA Logo"></img>
          <img src={r} alt="R Logo"></img>
          <img src={leo} alt="Leo Event Planner Logo"></img>
          <img src={emo} alt="Emo Logo"></img>
          <img src={geely} alt="Geely Logo"></img>
          <img src={goldenImperial} alt="Golden Imperial Logo"></img>
          <img src={goldenUnicorn} alt="Golden Unicorn Logo"></img>
          <img src={nyStudio} alt="NY Studio Logo"></img>
          <img src={orientalPalm} alt="Oriental Palm Logo"></img>
          <img src={otv} alt="OTV Logo"></img>
          <img src={sparks} alt="Sparks Logo"></img>
          <img src={zhenPin} alt="Zhen Pin Logo"></img>
          <img src={LG} alt="Lam Group Logo"></img>
          <img src={Lam} alt="Lam Generation Logo"></img>
          <img src={sunflower} alt="Sunflower Logo"></img>
          <img src={yeung} alt="Yeung Real Estate Logo"></img>
          <img src={century} alt="Century Development Logo"></img>
          <img
            src={afaci}
            alt="American Fujian Association of Commerce and Industry Inc. Logo"
          ></img>
          <img src={uhtag} alt="United Hong Tang Association Group Logo"></img>
          <img
            src={ulcaa}
            alt="United Lin Clan Association of America Logo"
          ></img>
        </ClientGrid>
      </AboutSection>
    </Layout>
  )
}

export default AboutPage

const AboutSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;

  h1 {
    text-decoration: underline;
    margin-bottom: 3rem;
    font-size: 3rem;

    @media screen and (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  h2 {
    margin-bottom: 2rem;
    font-size: 2.5rem;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }

  h1,
  h2,
  h3 {
    text-align: center;
    font-family: "Noto Serif Display", serif;
  }

  h3 {
    margin: 2rem auto;
    width: 80vw;
    max-width: 1200px;
  }

  p {
    width: 80vw;
    max-width: 1200px;
    margin: 0 auto;
  }

  .partner-text {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
`

const AboutHeader = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(147, 181, 179, 0.1)
    ),
    url("https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086867/couples/couple-62v_f0apja.jpg");
  width: 100%;
  height: 35vh;
  background-size: cover;
  background-position: center;
  margin-bottom: 3rem;

  @media screen and (max-width: 768px) {
    height: 25vh;
  }
`

const ServicesGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 3rem;

  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`

const ServiceBox = styled.div`
  position: relative;
  justify-self: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 25vw;
  max-height: 25vw;
  height: 300px;
  width: 300px;
  margin-bottom: 2rem;

  @media screen and (max-width: 460px) {
    max-width: 50vw;
    max-height: 50vw;
  }

  img {
    max-width: 25vw;
    max-height: 25vw;
    height: auto;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 460px) {
      max-width: 50vw;
      max-height: 50vw;
    }
  }

  div {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    color: white;
    font-family: "Courgette", cursive;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
`

const MCGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: 80vw;
  max-width: 1250px;
  margin: 0 auto;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

const LeadershipGrid = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 80vw;
  max-width: 1250px;
  margin: 0 auto;
  gap: 1rem;
`

const MCBox = styled.div`
  width: 25vw;
  height: 25vw;
  max-width: 400px;
  max-height: 400px;
  position: relative;
  justify-self: center;
  box-shadow: ${props =>
    props.showShadow ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "none"};

  overflow: hidden;

  @media screen and (max-width: 768px) {
    width: 35vw;
    height: 35vw;
  }

  @media screen and (max-width: 500px) {
    margin: 1rem auto 0 auto;
    width: ${props => props.leader ? "50vw" : "35vw"};
    height: ${props => props.leader ? "50vw" : "35vw"};
  }

  :hover img {
    transform: scale(1.1);
  }

  img {
    width: 100%;
    height: 100%;
    transition: all 0.2s ease;
  }

  div {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25%;
    background-color: rgba(242, 246, 245, 0.8);
  }

  p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    font-family: "Courgette", cursive;

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
    }

    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }
`

const MCGridInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  width: 80vw;
  max-width: 1250px;
  margin: 0 auto;
  gap: 1rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const MCBoxInfo = styled.div`
  display: flex;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  justify-self: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 35vw;
  }

  @media screen and (max-width: 500px) {
    width: 80vw;
  }
`

const MCInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  flex-grow: 1;

  @media screen and (max-width: 768px) {
    width: 35vw;
    align-items: flex-start;
  }

  @media screen and (max-width: 500px) {
    width: 80vw;
  }

  span {
    width: 100%;
    padding: 1.5rem;
    font-size: 1rem;

    @media screen and (max-width: 1200px) {
      font-size: 0.9rem;
    }

    @media screen and (max-width: 900px) {
      font-size: 0.8rem;
    }

    @media screen and (max-width: 768px) {
      width: 35vw;
    }

    @media screen and (max-width: 600px) {
      padding: 1rem;
      font-size: 0.7rem;
    }

    @media screen and (max-width: 500px) {
      width: 80vw;
    }
  }
`

const PhotographersPic = styled.img`
  width: 80vw;
  max-width: 800px;
  margin: 3rem auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`

const ClientGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  align-items: center;
  width: 80vw;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 460px) {
    grid-template-columns: 1fr 1fr;
  }

  img {
    max-width: 285px;
    width: 100%;
  }
`
